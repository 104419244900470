 /*jslint devel: true */
import blazy from './components/blazy';
import { navigation } from './components/navigation';
import { searchBar } from './components/search-bar';
import './components/list-styles';

// SCSS entry point
// import '../scss/style.scss';
// import '../scss/critical-style.scss';

/**
 * Script goes here
 */
blazy.init();

document.addEventListener('DOMContentLoaded', () => {
  navigation();
  searchBar();
});
