import Blazy from 'blazy';

const blazy = {};

blazy.init = () => { 
  const blazyer = new Blazy({
    breakpoints: [{
        width: 1020,
        src: 'data-src-medium'
      },
      {
        width: 769, // Max-width 
        src: 'data-src-small'
      }]
  });
};

blazy.checkImages = () => {
  const blazyer = new Blazy();
  blazyer.revalidate();
}

export default blazy;
