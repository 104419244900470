const ol = Array.from(document.querySelectorAll('ol[type="A"]'));

if(ol.length) {
  ol.map((list) => {
    if(list.getAttribute('type') === 'A') {
      list.classList.add('A');
    }
    if(list.getAttribute('type') === 'a') {
      list.classList.add('a');
    }
    if(list.getAttribute('type') === 'I') {
      list.classList.add('I');
    }
    if(list.getAttribute('type') === 'i') {
      list.classList.add('i');
    }
  });  
}
