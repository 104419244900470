import state from '../helper/state';

const navigation = () => {
  const bodyNode = document.querySelector('body');
  const hamburgerButton = document.querySelector('.c-header-hamburger');
  const mainNavigation = document.querySelector('nav.c-nav');
  const navigationCategoryArray = Array.from(mainNavigation.querySelectorAll('.c-nav-primary-category'));

  // Launches navigation
  hamburgerButton.addEventListener('click', (e) => {
    e.preventDefault();
    checkSearchBar();
    toggleNavigation(hamburgerButton, mainNavigation, bodyNode);
  });

  // Category accordion
  navigationCategoryArray.forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      toggleCategory(button, mainNavigation, navigationCategoryArray);
    });
  });

  document.addEventListener('keydown', (event) => {
    if (event.keyCode === 27 && state.navigation) {
      hamburgerButton.click();
      hamburgerButton.focus();
    }
  });

  document.addEventListener('click', (event) => {
    const isClickInside = document.querySelector('.c-header').contains(event.target);
    if (!isClickInside && state.navigation) {
      //the click was outside the specifiedElement, do something
      hamburgerButton.click();
    }
  });

  // mainNavigation.style.display = 'block';
  tabTrap(mainNavigation, hamburgerButton);
};

const toggleNavigation = (button, mainNavigation, bodyNode) => {
  if (state.navigation) {
    state.navigation = false;

    button.classList.remove('active');
    button.setAttribute('aria-expanded', false);
    bodyNode.classList.remove('static', 'nav-active');
    mainNavigation.setAttribute('aria-hidden', true);
    mainNavigation.classList.remove('active');
    mainNavigation.setAttribute('tabindex', '-1');
    setTimeout(() => {
      mainNavigation.classList.add('hidden');
    }, 350);

    } else {
      state.navigation = true;

      button.classList.add('active');
      button.setAttribute('aria-expanded', true);
      bodyNode.classList.add('static', 'nav-active');
      mainNavigation.setAttribute('aria-hidden', false);
      mainNavigation.setAttribute('tabindex', '0');
      mainNavigation.classList.remove('hidden');
      mainNavigation.classList.add('active');
    }
};

const toggleCategory = (button, navigation, buttonArray) => {
  const submenuOpenArray = Array.from(navigation.querySelectorAll('.c-nav-submenu'));
  const nextSubmenu = button.nextElementSibling;
  submenuOpenArray.forEach((submenu) => {
    if (submenu === nextSubmenu && !nextSubmenu.classList.contains('active')) {
      nextSubmenu.classList.add('active')
      nextSubmenu.setAttribute('aria-hidden', false);
      
    } else {
      submenu.classList.remove('active');
      submenu.setAttribute('aria-hidden', true);
    }
  });

  buttonArray.forEach((buttonNode) => {
    if (buttonNode === button) {
      if (button.getAttribute('aria-expanded') === 'false') {
        button.setAttribute('aria-expanded', true);
      } else {
        button.setAttribute('aria-expanded', false);
      }
    } else {
      buttonNode.setAttribute('aria-expanded', false);
    }
  })
}

const tabTrap = (navigationNode, buttonNode) => {
  const tabArray = Array.from(navigationNode.querySelectorAll('a'));
  tabArray[tabArray.length - 1].addEventListener('keydown', (event) => {
    if (event.keyCode === 9 && !event.shiftKey && state.navigation) {
      event.preventDefault();
      buttonNode.focus();
    }
  })
}

const checkSearchBar = () => {
  if(state.searchBar) {
    const searchBtnNode = document.querySelector('.search-button');
    searchBtnNode.click();
  }
}

export {navigation};
