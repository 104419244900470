import state from '../helper/state';

const searchBar = () => {
  const searchBtnNode = document.querySelector('.search-button');
  const searchBarNode = document.querySelector('.c-search-container');
  const searchInputNode = searchBarNode.querySelector('input[type=text]');
  const searchFocusArr = Array.from(searchBarNode.querySelectorAll('input'));


  searchBtnNode.addEventListener('click', (event) => {
    event.preventDefault();
    checkNavigation();
    toggleSearchBar(searchBarNode, searchInputNode, searchBtnNode);
  });

  tabTrap(searchFocusArr, searchBtnNode);

  // function is duplicated in navigation.js
  document.addEventListener('click', (event) => {
    const isClickInside = document.querySelector('.c-header').contains(event.target);
    if (!isClickInside && state.searchBar) {
      //the click was outside the specifiedElement
      searchBtnNode.click();
    }
  });

  document.addEventListener('keydown', (event) => {
    if (event.keyCode === 27 && state.searchBar) {
      searchBtnNode.click();
      searchBtnNode.focus();
    }
  });
};

const toggleSearchBar = (searchBarNode, searchInputNode, searchBtnNode) => {
  // const searchBtnText = searchBtnNode.querySelector('span');
  const bodyNode = document.querySelector('body');

  if (state.searchBar) {
    state.searchBar = false;

    searchBarNode.setAttribute('aria-hidden', true);
    searchBarNode.classList.remove('active');
    bodyNode.classList.remove('nav-active');
    searchBarNode.setAttribute('tabindex', '-1');

    searchBtnNode.setAttribute('aria-expanded', false);
    // searchBtnText.classList.add('hidden');

    if(!state.navigation) {
      searchBtnNode.focus();
    }

    setTimeout(() => {
      searchBarNode.classList.add('hidden');
    }, 350);

  } else {
    state.searchBar = true;
    bodyNode.classList.add('nav-active');
    searchBarNode.classList.remove('hidden');
    searchBarNode.classList.add('active');

    searchBarNode.setAttribute('aria-hidden', false);
    searchBarNode.setAttribute('tabindex', '0');

    searchBtnNode.setAttribute('aria-expanded', true);

    setTimeout(() => {
      searchInputNode.focus();
    }, 350);
  }
};

const checkNavigation = () => {
  if (state.navigation) {
    const navigationBtnNode = document.querySelector('.c-header-hamburger');
    navigationBtnNode.click();
  }
};

const tabTrap = (searchFocusArr, searchBtnNode) => {
  searchFocusArr[0].addEventListener('keydown', (event) => {
    if (event.keyCode === 9 && event.shiftKey && state.searchBar) {
      event.preventDefault();
      searchBtnNode.focus();
    }
  });

  searchFocusArr[searchFocusArr.length - 1].addEventListener('keydown', (event) => {
    if (event.keyCode === 9 && !event.shiftKey && state.searchBar) {
      event.preventDefault();
      searchBtnNode.focus();
    }
  });
}

export { searchBar };
